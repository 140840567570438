<template>
  <div id="sceneui">
    <div id="logoHolder">
      <img :src="require('../assets/icons/logo.png')">
    </div>
  </div>
</template>

<script>

export default {
  name: 'SceneUi'
}
</script>

<style scoped>
#sceneui
{
  position: absolute;

  width: 100vw;
  padding: 1vh;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  pointer-events: none;
}
/* Logo */
#logoHolder
{
  height: 10vh;
}
#logoHolder img
{
  height: 100%;
  width: 100%;
  object-fit: contain;
}
</style>