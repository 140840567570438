<template>
  <Popup ref="popup" @close="popupClosed"/>
  <SceneUi ref="ui"/>
  <canvas id="canvas3D"/>
</template>

<script>
import Popup from './Popup.vue'
import SceneUi from './SceneUi.vue'

import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

import { Line2, LineGeometry, LineMaterial } from 'three-fatline'

import gsap from "gsap"

import Stats from 'stats.js'

export default {
  name: 'Scene3d',
  components: { Popup, SceneUi },
  data(){
    return {
      navPoints: require('../assets/NavigationPoints.json'),
      currentPoint: null,
      sceneGroup: null,
      camera: null,
      control: null,
      cameraXZ: [0, 0],
      cameraSpeedSeconds: 2,
      animating: false,
      animationList: [null, null],
      isPaused: true,
      lastMovement: 0,
      autoRotate: false,
    }
  },
  methods: {
    zoomOut() {
      const zoomDuration = 2
      this.animationList = []
      this.animationList.push(gsap.to(this.camera.position, {x: this.cameraXZ[0] * 3, duration: zoomDuration, ease: "expo"}))
      this.animationList.push(gsap.to(this.camera.position, {z: this.cameraXZ[1] * 3, duration: zoomDuration, ease: "expo"}))

      this.animating = true
      setTimeout(() => {
        this.animating = false
      }, zoomDuration * 1000)
    },
    updateIndex(index, rotation){
      this.pause()
      const local = this
      const point = this.navPoints[index]

      // Calculate Y
      let y
      if (point.position.y > 1.5){
        y = point.position.y * 1.2
      }
      else{
        y = point.position.y * 0.8
      }

      gsap.to(this.camera.position, {y: y, duration: this.cameraSpeedSeconds, ease: "expo"})

      // Calculate Distances
      this.control.autoRotate = true
      if (rotation == 0){
        this.control.autoRotateSpeed = 25
      }
      else{
        this.control.autoRotateSpeed = -25
      }
      
      this.control.enableDamping = false

      let isApproching = false

      let lastDistance = null
      const tick = setInterval(check, 10) 

      function check(){ 
        const actualCameraDistance = getDistance(local.camera, point)

        if (lastDistance && actualCameraDistance < lastDistance){
          isApproching = true
        }

        if (actualCameraDistance > lastDistance && isApproching){
          clean()
        }

        lastDistance = actualCameraDistance 
      }

      this.control.addEventListener('start', () => {
        clean()
      })
      
      function clean(){
        clearInterval(tick)
        local.start()
        local.control.autoRotateSpeed = 2
        local.control.enableDamping = true        
      }

      function getDistance(mesh1, mesh2) { 
        var dx = mesh1.position.x - mesh2.position.x; 
        var dy = mesh1.position.y - mesh2.position.y; 
        var dz = mesh1.position.z - mesh2.position.z; 
        return Math.sqrt(dx*dx+dy*dy+dz*dz); 
      }
    },
    animateCamera(point){
         
      let y
      if (point.position.y > 1.5){
        y = point.position.y * 1.2
      }
      else{
        y = point.position.y * 0.8
      }

      this.cameraXZ = [point.position.x, point.position.z]

      gsap.to(this.camera.position, {x: point.position.x * 1.8, duration: this.cameraSpeedSeconds, ease: "expo"})
      gsap.to(this.camera.position, {y: y, duration: this.cameraSpeedSeconds, ease: "expo"})
      gsap.to(this.camera.position, {z: point.position.z * 1.8, duration: this.cameraSpeedSeconds, ease: "expo"})

      this.start()
    },
    welcomeClosed(){
      const animationDuration = 5

      this.animationList = []
      this.animationList.push(gsap.to(this.camera.position, {x: -4, duration: animationDuration}))
      this.animationList.push(gsap.to(this.camera.position, {z: -4, duration: animationDuration}))

      this.animating = true
      setTimeout(() => {
        this.animating = false
      }, animationDuration * 1000)
      
      this.start()
    },
    popupClosed(){
      this.zoomOut()
      this.start()
    },
    pause(){
      this.isPaused = true
    },
    start(){
      this.isPaused = false
      this.lastMovement = performance.now()
      this.autoRotate = false

      this.control.autoRotate = false
    },
    openPopup(id){
      const element = this.navPoints[id]

      this.animateCamera(element)     
      
      this.$refs.popup.updatePage(element)
      this.pause()     
    }
  },
  mounted(){
    const local = this

    // ========= // 
    // Framerate //
    // ========= //      
    let displayFPS = false
    let stats
    if(location.hash.match('fps')){
      displayFPS = true

      stats = new Stats();
      stats.showPanel( 0 ); // 0: fps, 1: ms, 2: mb, 3+: custom
      document.body.appendChild( stats.dom ); 
    }    

    // ======== // 
    // Renderer //
    // ======== //         
    const canvas = document.getElementById('canvas3D')
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias: true,
      alpha: true
    })
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.PCFSoftShadowMap
    renderer.setClearColor( 0x000000, 0 )

    // ===== // 
    // Scene //
    // ===== //      
    const scene = new THREE.Scene()

    // ====== // 
    // Camera //
    // ====== //
    const camera = new THREE.PerspectiveCamera(75, 0, 0.1, 100)
    camera.position.set(-3, 3, -3)
    scene.add(camera)

    this.camera = camera
    
    // ==== // 
    // Mesh //
    // ==== //
    //Loaders
    const gltdLoader = new GLTFLoader()
    const loader = new THREE.TextureLoader()

    // Building
    gltdLoader.load('building.glb', gltf => {
      const building = gltf.scene
      building.scale.set(0.015, 0.015, 0.015)

      building.traverse((child) => {
        if(child instanceof THREE.Mesh){
          child.material = new THREE.MeshStandardMaterial({
            map: child.material.map
          })

          child.castShadow = true
          child.receiveShadow = true
        }
      })

      scene.add(building)
      
      this.$emit('load')
    })

    // Constant geometry / materials
    const pointGeometry = new THREE.CircleGeometry(0.2, 35)

    const littleCircleGeometry = new THREE.CircleGeometry(0.04, 15)
    const littleCircleMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff })

    const lineMaterial = new LineMaterial({ color: 0xffffff, linewidth: 0.005 - window.innerWidth * 0.000001})

    let navMeshes = []
    let navHitbox = []

    const hitGeometry = new THREE.BoxGeometry(0.4, 0.4, 0.4)
    const hitMaterial = new THREE.MeshBasicMaterial()
    
    // Iterate over the json
    let i = 0
    for (let point of this.navPoints){
      // Load icon
      const id = i
      const buildingSidePosition = [
        point.position.x * point.lineBuildingDistance, 
        point.lineHeight, 
        point.position.z * point.lineBuildingDistance
      ]

      loader.load(
        require(`../assets/icons/navIcons/${point.logo}`), 
        function( texture ){
          // Create mesh & Apply Texture
          const mesh = new THREE.Mesh(
            pointGeometry,
            new THREE.MeshBasicMaterial({
              map: texture
            })            
          )

          mesh.position.set(point.position.x, point.position.y, point.position.z)

          scene.add(mesh)          
          navMeshes.push(mesh)
          
          // Small circles
          const circle = new THREE.Mesh( littleCircleGeometry, littleCircleMaterial)
          circle.position.set(buildingSidePosition[0], buildingSidePosition[1], buildingSidePosition[2])

          scene.add(circle)          
          navMeshes.push(circle)

          // Create Hitbox          
          const hitBox = new THREE.Mesh(hitGeometry, hitMaterial)
          hitBox.position.set(point.position.x, point.position.y, point.position.z)
          hitBox.name = id

          hitBox.visible = false

          scene.add(hitBox)
          navHitbox.push(hitBox)

          local.$emit('load')
        }
      )      
      i++
        
      // Create Line 
      const lineGeometry = new LineGeometry()
      lineGeometry.setPositions([        
        buildingSidePosition[0], buildingSidePosition[1], buildingSidePosition[2],
        point.position.x * point.lineDistanceAtIcon , point.position.y, point.position.z * point.lineDistanceAtIcon
      ])

      const line = new Line2(
        lineGeometry,
        lineMaterial
      )

      scene.add(line)      
    }

    // ======= // 
    // Control //
    // ======= //
    const control = new OrbitControls(camera, canvas)
    control.target.set(0, 1.5, 0)
    this.control = control

    control.enableDamping = true
    control.enablePan = false
    control.autoRotateSpeed = -2

    control.maxDistance = 7
    control.minDistance = 2.5
    control.minPolarAngle = Math.PI * 0.2
    control.maxPolarAngle = Math.PI * 0.8

    // Circles looking at the camera
    let cameraMooved = false

    control.addEventListener('change', () => {
      cameraMooved = true   
    })

    // Setup position before any movement
    setTimeout(() => {
      cameraMooved = true
    }, 500)

    function updateCirclesAngle(){
      for(let circle of navMeshes){
        circle.lookAt(camera.position)
      }
    }

    // ====== // 
    // Lights //
    // ====== //
    const ambiantLight = new THREE.AmbientLight(0xffffff, 0.5)
    scene.add(ambiantLight)

    const pointLight  = new THREE.PointLight(0xffffff, 1.5)
    pointLight.position.set(-5, 4, -2)
    pointLight.castShadow = true

    scene.add(pointLight)

    // ========= // 
    // Raycaster //
    // ========= //
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    canvas.addEventListener('click', event => {
      mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
      mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
      
      raycaster.setFromCamera( mouse, camera )
      const intersects = raycaster.intersectObjects( navHitbox )

      if (intersects.length > 0){
        // Animate scene
        this.openPopup(intersects[0].object.name)
      }
    })

    // =========== // 
    // Auto Rotate //
    // =========== //
    // Auto rotation
    control.addEventListener('start', () => {
      this.lastMovement = performance.now()

      this.autoRotate = false
      control.autoRotate = false

      // Cancel animation if user interact
      if (this.animating){        
        for (let animation of this.animationList){
          animation.kill()
        }
      }
    })

    // ==== // 
    // Loop //
    // ==== //
    const tick = () =>
    {            
      // Framerate
      if (displayFPS){
        stats.begin();
        stats.end();
      }

      // Autorotate
      if (!this.isPaused && !this.autoRotate && performance.now() - this.lastMovement > 2500){ 
        this.autoRotate = true

        control.autoRotate = true
      }

      // Update control
      control.update()

      // If the camera moved, update circles angle
      if (cameraMooved){
        cameraMooved = false
        updateCirclesAngle()
      }      

      // Render
      renderer.render(scene, camera) 

      // Recall
      window.requestAnimationFrame(tick)      
    }
    tick()

    // ============= // 
    // Window Resize //
    // ============= //    
    // Function
    function updateSizes(){      
      // Store new sizes
      const sizes = {
        height: window.innerHeight,
        width: window.innerWidth
      }

      // Update camera
      camera.aspect = sizes.width / sizes.height
      camera.updateProjectionMatrix()

      // Update renderer
      renderer.setSize(sizes.width, sizes.height)
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    }

    window.addEventListener('resize', () => {
      updateSizes()
    })

    updateSizes()
  }
}
</script>
