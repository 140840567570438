<template>
  <LoadingScreen ref="loadingScreen"/>
  <Welcome @closed="closed"/>
  <Scene3d ref="scene3D" @load="load"/>
</template>

<script>
import Scene3d from './components/Scene3d.vue'
import Welcome from './components/Welcome.vue'
import LoadingScreen from './components/LoadingScreen.vue'

export default {
  name: 'App',
  components: { Scene3d, Welcome, LoadingScreen },
  data(){
    return {
      laodCount: 0
    }
  },
  methods: {
    closed(){
      this.$refs.scene3D.welcomeClosed()
    },
    load(){
      const maxElementCount = 8
      this.laodCount += 1

      this.$refs.loadingScreen.update(100 / (maxElementCount / this.laodCount))

      if (this.laodCount == maxElementCount){
        this.$refs.loadingScreen.closeLoader()
      }
    }
  }
}
</script>

<style>
.gpuAcceleration
{
  will-change: transform;
}
</style>
