<template>
  <div id="loadingScreen" class="gpuAcceleration" v-if="laoding">
    <div id="loadingLogoHolder">
      <img :src="require('../assets/icons/logo.png')">
    </div>
    <div id="loader">
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingScreen',
  data(){
    return{
      laoding: true
    }
  },
  methods: {
    update(percentage){
      document.getElementById('loader').style.clipPath = `polygon(0 0, ${percentage}% 0, ${percentage}% 100%, 0% 100%)`
    },
    closeLoader(){
      document.getElementById('loadingScreen').style.transform = "translateY(100vh)"

      setTimeout(() => {
        this.laoding = false
      }, 2500)
    }
  }
}
</script>

<style scopde>
#loadingScreen
{
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 10;

  background-color: #231f20;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  transition-duration: 700ms;
  transition-delay: 1000ms;
  transition-timing-function: ease-in-out;
}
#loadingLogoHolder
{
  height: 15vh;
  width: 15vh;
}
#loadingLogoHolder img
{
  height: 100%;
  width: 100%;
  object-fit: contain;
}
#loader
{
  height: 0.5vh;
  width: 20vh;

  margin-top: 5vh;

  background-color: white;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
}
</style>