<template>
  <div id="popup" class="gpuAcceleration">
    <div id="close" @click="close">
      <img :src="require('../assets/icons/close.png')">
    </div>
    <div id="popUpBody">
      <div v-if="componentName == 1" class="popupPage">
        <p class="textDecoration">
          Royalty to play to earn system:
        </p>
        <p >
          Grab your nft and play our pong game to get a chance to win a portion of the royalty, up to a rolex worth of money every bi-month.<br><br>Play the game and Rise to the top of the leaderboard, every 2 month, the top 100 of the leaderboard would be picked on a one week based tournament with knockout rule, the last one standing will win the entire amount of the cash.
        </p>
        <iframe src="https://i.simmer.io/@Rolexana/rolexanaa" style="width:960px;height:600px;border:0"></iframe>
      </div>
      <div v-else-if="componentName == 2" class="popupPage">
        <p class="textDecoration">
          8Bits Collections made from a 41 base rolex watch database:
        </p>
        <p>
          In rolexana we value simplicity.<br>
          Over 41 base rolexana with a possibilities of over 234 traits.<br>
        </p>
        <div id="gifHolder">
          <img :src="require('../assets/pagesAssets/rolexana.gif')">
        </div>
      </div>
      <div v-else-if="componentName == 3" class="popupPage">
        <p class="textDecoration">
          Paper hand Tax and buyback for a deflationary system:
        </p>
        <p>
          We value innovation but bringing whats the best for the community is one our priority.<br><br>
          We are on discussion to implement the tax bitches initiated by degods, selling at lower than floor price will enable a 33% tax which we will then use to buy the cheaper NFT to increase the floor price and burn the nft.
        </p>
      </div>
      <div v-else-if="componentName == 4" class="popupPage">
        <p class="textDecoration">
          Holding bonus for long term and multiples pieces:
        </p>
        <p>
          We value long term holder, we initiated a long term bonus.<br><br>
          Holder would appreciate the bonus boost up, holding the NFT without listing double your score every month in the leaderboard, listing to sell the NFT will erase the bonus and bring back your score to 0.<br><br>Holding more than one nft double your points every 2 month ,every one rolexana NFT on the wallet increase your points by 2, therefore holding multiples nfts and for a long period will almost guarantee you to be on the top 100, to participate in the knockout stage.
        </p>
      </div>
      <div v-else-if="componentName == 5" class="popupPage">
        <p class="textDecoration">
          Good looking art:
        </p>
        <p>
          Simple art but good lookin.Check out some of our beast.
        </p>
        <div id="gallery">
          <div class="galeryPicture">
            <img :src="require('../assets/pagesAssets/Batwatch.png')">
          </div>
          <div class="galeryPicture">
            <img :src="require('../assets/pagesAssets/Blue king.png')">
          </div>
          <div class="galeryPicture">
            <img :src="require('../assets/pagesAssets/Butterflycorn.png')">
          </div>
          <div class="galeryPicture">
            <img :src="require('../assets/pagesAssets/Darkastronaute.png')">
          </div>
          <div class="galeryPicture">
            <img :src="require('../assets/pagesAssets/Devil cypher.png')">
          </div>
          <div class="galeryPicture">
            <img :src="require('../assets/pagesAssets/Golden Beast.png')">
          </div>
          <div class="galeryPicture">
            <img :src="require('../assets/pagesAssets/Purple viking.png')">
          </div>            
        </div>
      </div>
      <div v-else-if="componentName == 6" class="popupPage">
        <p>
          For more update follow us ! 
        </p>
        <div id="links">
          <a href="https://twitter.com/RolexanaNFT" target="_blank">
            <div class="link">
              <img :src="require('../assets/icons/twitter.png')">              
            </div>
          </a> 
          <a href="https://discord.gg/BnxD8SGzB6" target="_blank">
            <div class="link">
              <img :src="require('../assets/icons/discord.png')">     
            </div>
          </a> 
        </div>
      </div>
      <div v-else-if="componentName == 7" class="popupPage">
        <p class="textDecoration">
          Mint price and giveaway info:
        </p>
        <p>
          4444 rolexana fixed to 0.2 Sol each.<br><br>
          The big knockout stage will happen every 2 month, top 100 players will fight on a knockout stage to win up to 14 000 dollars worth of solana.<br><br>100% of the royalties will go into that.<br><br>
          Not just that we are planning on using the the funds to reinvest on other project such as staking and more for passive income to increase the prize pool as much as possible.
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Popup',
  data(){
    return {
      componentName: ""
    }
  },
  methods: {
    close(){
      document.getElementById('popup').style.transform = "translateY(100vh)"

      this.$emit('close')
      
      setTimeout(() => {
        this.content = null     
      }, 500)
    },
    updatePage(element){
      this.componentName = element.page
      this.waitElementCreation()
    },
    waitElementCreation(){
      const loop = setInterval(check, 50)

      function check(){ 
        if (document.getElementById('popup')){
          
          setTimeout(() => {
            document.getElementById('popup').style.transform = "translateY(0vh)"
          }, 500)

          clearInterval(loop)
        }
      }
    }
  }
}
</script>

<style scoped>
#popup
{
  position: absolute;

  width: 100vw;
  height: 100vh;

  padding-top: 2vh;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;

  transform: translateY(100vh);
  transition-duration: 500ms;
}
#close
{
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0px;

  width: 5vh;
  height: 5vh;

  margin: 1%;
  z-index: 2;
}
#popUpBody
{
  color: black;
}
.link
{
  width: 10vh;
  height: 10vh;
}
#links
{
  display: flex;
  flex-direction: row;
  pointer-events: none;
}
#links a
{
  pointer-events: all;

  transition-duration: 200ms;
}
#links a:hover
{
  transform: scale(1.05);
}
.galeryPicture img,
#close img,
#gifHolder img,
.link img
{
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.popupPage
{
  position: absolute;
  top: 0;
  left: 0;

  width: 100vw;
  min-height: 100vh;

  display: flex;
  align-items: center;
  flex-direction: column;

  /* pointer-events: none; */
}
.popupPage p
{
  text-align: center;
}
#gallery
{
  width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.galeryPicture
{
  width: 42.5vw;
  height: 42.5vw;

  margin: 5vw;
  margin-right: 0vw;
}
#gifHolder
{
  width: 30vw;
  height: 30vw;
  margin: 5vw;
}
.textDecoration
{
  text-decoration: underline black;
}
@media screen and (orientation: landscape) {  
  .popupPage
  {
    padding: 5vh;
    padding-top: 15vh;

    font-size: 4vh;
  }
}
@media screen and (orientation: portrait) {  
  .popupPage
  {
    padding: 5vw;
    padding-top: 15vw;

    font-size: 4vw;
  }
}
</style>