<template>
  <div id="Welcome" class="gpuAcceleration">
    <div id="welcomeBody">
      <div id="logoHolder">
        <img :src="require('../assets/icons/logo.png')">
      </div>
      <p id="description">
        Rolexana,<br>simplicity at its finest.<br><br>Minting coming soon.
      </p>
      <div id="startButton" @click="close">
        START
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Welcome",
  methods: {
    close(){       
      document.getElementById('Welcome').style.transform = "translateY(100vh)"
      document.getElementById('Welcome').style.opacity = "0"  

      this.$emit('closed')
    }
  },
  mounted(){    
    // Calculate screen height for mobile device
    const welcome = document.getElementById('Welcome')
    window.addEventListener('resize', () => {
      updateHeight()    
    })

    function updateHeight(){
      if (welcome){
        const vh = window.innerHeight * 0.01

        welcome.style.setProperty('--vh', `${vh}px`)
      }
    }

    updateHeight()
    setTimeout(() => {
      updateHeight()
    }, 500)
  }
}
</script>

<style scoped>
#Welcome
{
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;

  transition-duration: 500ms;
}
#welcomeBody
{
  width: 90vw;
  max-height: 90vh;

  padding: 4vw;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: white;
}
#logoHolder,
#description,
.instructions,
#startButton
{
  margin: 1.6vh 0;
}
#logoHolder
{
  height: 15vh;
}
#logoHolder img
{
  height: 100%;
  width: 100%;
  object-fit: contain;
}
#description
{
  text-align: center;
}
/* Button */
#startButton
{
  color: white;
  background-color: #1a1a1a;

  padding: 3vw;
  width: 40vw;

  text-align: center;
  cursor: pointer;
}
#description,
#startButton
{
  font-size: 2.5vw;
}
</style>